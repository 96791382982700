/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@font-face {
   font-family: Keem;
   src: url(/public/assets/KEEM.ttf);
 }

 body {
   overflow: hidden;
   filter: invert(1) contrast(10) grayscale(1);
 }

main {
   width: 100vw;
   height: 100vh;
   overflow: hidden;
}

.enter {
   position: absolute;
   width: 100vw;
   height: 100%;
   font-family: "Keem";
   text-transform: uppercase;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   background-color: #f4f4f4;
   color: rgba(65,65,65);
   z-index: 10 !important;
   
}

.notch {
   position: absolute;
   width: 100vw;
   height: 100%;
   top: 0;
   left: 0;
   pointer-events: none;
}

.notch svg {
   width: 20vw;
   height: auto;
   margin-top: -4.95%;
   margin-left: 75%;
}

canvas {
 filter: saturate(0.5) hue-rotate(5deg)
}

.enter p:hover {
   color: rgba(25,25,25);
   cursor: pointer;
}

.diction {
   z-index: 1;
   position: absolute;
   left: 50%;
   top:85%;
   -webkit-transform: translate(-50%, 0%);
       -ms-transform: translate(-50%, 0%);
           transform: translate(-50%, 0%);
   pointer-events: none;
}

.diction p {
   color: rgb(91, 91, 91);
   font-family: "Keem";
   text-transform: uppercase;
}


@media (max-width: 576px) {
   .diction, .enter {
      font-size: 0.8rem;
      /* display: none; */
   }

   .notch svg {
      width: 40vw;
      margin-top: -38px;
      margin-left: 65%;
   }

 }